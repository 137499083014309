<template>
    <el-dialog
        class="editDialog"
        :append-to-body="true"
        width="600px"
        :visible.sync="showEditDialog"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        @close="$emit('update:isShow', false)"
        :show-close="true"

    >

        <div slot="title" class="title">{{ options.id ? '编辑' : '新增' }}字典</div>

        <div class="content" v-loading="loading">
            <el-form label-width="6em" :model="form" :rules="rules" ref="form">

                <el-form-item label="父级字典" prop="parentId" ref="parentId">
                    <el-cascader
                        v-model="form.parentId"
                        :options="treeselectOptions"
                        :props="{ expandTrigger: 'hover',checkStrictly:true,emitPath:false,label:'name',value:'id' }"
                        @change="changeParentId"></el-cascader>
                </el-form-item>
                <el-form-item label="字典名称" prop="name">
                    <el-input @change="nameChange" v-model="form.name" placeholder="请输入字典名称" @keyup.enter.native="submit"></el-input>
                </el-form-item>
                <el-form-item label="字典值" prop="val">
                    <el-input v-model="form.val" placeholder="请输入字典值" @keyup.enter.native="submit"></el-input>
                </el-form-item>

                <el-form-item label="备注" prop="bz">
                    <el-input type="textarea" v-model="form.bz" placeholder="请输入备注" @keyup.enter.native="submit"></el-input>
                </el-form-item>

            </el-form>
        </div>

        <div slot="footer" v-show="!loading">
            <el-button type="primary" :loading="submitLoading" @click="submit">保存</el-button>
            <el-button type="info" plain @click="$emit('update:isShow', false)">取消</el-button>
        </div>

    </el-dialog>

</template>
<script>
import {initTreeData} from '@/util/common'
import mixinsForm from '@/mixins/form'
export default {
    components: {
    },
    mixins: [mixinsForm],
    props: {
        rulesData: {
            type: Array,
            default:function(){
                return []
            }
        },
        treeselectOptions: {
            type: Array,
            default:function(){
                return []
            }
        },
    },
    data() {
        return {
            api:{
                save:this.$api['admin/dict'].save,
            },
            rules: {
                parentId: [{required: true, message: '请选择父级字典', trigger: ['blur','change']}],
                name: [{ required: true, message: '请输入字典名称', trigger:['blur','change'] }],
                val: [
                    { required: true, message: '请输入字典值', trigger:['blur','change'] },
                    {
                        trigger: ['change', 'blur'],
                        validator: (rule, value, callback) => {
                            if(this.rulesData.some(r=>{return r.id!=this.form.id&&r.val==value})){
                                callback(new Error('字典值不能重复'));
                            };
                            callback();
                        }
                    }
                ],
            },
        };
    },
    created() {},
    methods: {
        nameChange(a){
            if(!this.form.val){
                this.form.val = a.getPinyin('_')
            };
        },
        changeParentId(){
            this.$refs.form.validateField('parentId');
        },
    },
};
</script>
